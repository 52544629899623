import React from "react";

function EDS() {
  return (
    <section className="flex flex-col lg:px-[70px]  ">
      <div className="flex flex-col lg:flex-row gap-6 mt-6">
        <div className="flex flex-col gap-2">
          <div className="flex-1 bg-[#EAF4FA] p-6 max-w-[640px] ">
            <h4 className="font-semibold mb-2">
              Protean (formerly NSDL eGov) e-Gov e-TDS/TCS Return Preparation
              Utility (RPU)
            </h4>
            <p className="text-[15px] font-light">
              TDS/TCS statements are furnished on quarterly basis, Now,
              quarterly statements pertaining to Financial Year 2007-08 onwards
              are accepted as per the guidelines of Income Tax Department.
            </p>
            <p className="text-[15px] font-light">
              ITD has notified revised file formats for preparation of TDS (i.e.
              Tax Deducted at source) and TCS (i.e. Tax collected at source)
              statements . Deductors/collectors are required to prepare
              e-TDS/TCS statements as per these file formats using, Protean
              (formerly NSDL eGov) e-Gov. Return Preparation Utility or in-house
              software or any other third party software and submit the same to
              any of the TIN-FCs established by Protean (formerly NSDL eGov)
              e-Gov.
            </p>
            <p className="text-[15px] font-light">
              Protean (formerly NSDL eGov) e-Gov has developed software called
              e-TDS/TCS Return Preparation Utility (RPU) to facilitate
              preparation of e-TDS/ TCS returns. This is a freely downloadable
              VB based utility. Separate utilities are available for preparation
              of each type of statement.
            </p>
          </div>
          <div className="flex-1 bg-[#EAF4FA] p-6 max-w-[640px]">
            <h4 className="font-semibold mb-2">Running the FVU</h4>
            <p className="text-[15px] font-light">
              These utilities have been developed by Protean (formerly NSDL
              eGov) e-Gov for small deductors/collectors and returns exceeding
              20,000 deductee records should not be prepared using this utility.
              Protean (formerly NSDL eGov) e-Gov does not warrant any accuracy
              of the output file generated using any of these utilities. All
              users are advised to use latest FVU and check the format level
              correctness of the file before submitting the same to TIN-FC. In
              case FVU reports any error in the file, then the users are advised
              to rectify the same. Further, deductors/collectors are advised to
              ensure that the e-TDS/TCS returns are filed before the last date
              specified by Income Tax Department. Non-functioning or non
              availability of this utility may not be considered as a reason for
              inability to file the return before the last date.
            </p>
          </div>
        </div>
        <div className="flex-1 bg-[#EAF4FA] p-4 md:p-6  md:px-10">
          <h4 className="font-semibold mb-2">RPU for Quarterly Returns</h4>
          <p className="text-[15px] font-light">
            From Financial Year 2005-06 onwards, TDS/TCS statements have to be
            filed on quarterly basis; however, as advised by Income Tax
            Department, acceptance of TDS/TCS statements prior to the FY 2007-08
            has been discontinued at TIN. The following utility can be used to
            prepare quarterly regular as well as correction statements:
          </p>
          <a href="https://www.protean-tinpan.com/downloads/e-tds/download/TDS_RPU_5.3.zip" className="mt-4 text-[15px] font-light">
            New e-TDS/TCS Return Preparation Utility Ver. 5.2 for Regular & Correction Statement(s) from FY 2007-08 onwards.{" "}
          </a>
          <div className="flex flex-col xl:flex-row items-end justify-between mt-4 text-[15px] font-light">
            <div className="flex flex-col">
              <div className="flex flex-col gap-2">
                <div className="flex justify-between items-start border-b border-b-black py-2  lg:max-w-[227px]">
                  <a
                    href="https://www.protean-tinpan.com/downloads/e-tds/download/TDS_RPU_5.3.zip"
                    className="text-[#1D1D1B] cursor-pointer text-base  lg:max-w-[227px]"
                  >
                    Download RPU version 5.3{" "}
                  </a>
                  <div>
                    <svg
                      className="text-[#1D1D1B]"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.9495 3.05078L3.04997 12.9503"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.9495 8.70764V3.05078"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.29291 3.05108H12.9498"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex justify-between items-start border-b border-b-black py-2  lg:max-w-[227px]">
                  <a
                    href="/RPU e-Tutorial on TDS TCS.pdf"
                    className="text-[#1D1D1B] cursor-pointer text-base  lg:max-w-[227px]"
                  >
                    RPU_e-Tutorial_on_TDS_TCS
                  </a>
                  <div>
                    <svg
                      className="text-[#1D1D1B]"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.9495 3.05078L3.04997 12.9503"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.9495 8.70764V3.05078"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.29291 3.05108H12.9498"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <div className="flex justify-between items-start border-b border-b-black py-2  lg:min-w-[227px] lg:max-w-[227px]">
                <a
                  href="https://www.protean-tinpan.com/downloads/e-tds/download/Key_Features-RPU-version_5.3.pdf"
                  className="text-[#1D1D1B] cursor-pointer text-base   "
                >
                  Features of RPU 5.3
                </a>
                <div>
                  <svg
                    className="text-[#1D1D1B]"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.9495 3.05078L3.04997 12.9503"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.9495 8.70764V3.05078"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.29291 3.05108H12.9498"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className="text-[15px] mt-4 text-[15px] font-light">
              Guidelines for usage of these RPUs are provided in the respective
              utilities. The users are advised to read these guidelines
              carefully before the utility is used to prepare the returns. Users
              may ensure that they download the latest version of the utility at
              the time of preparation of return. Users must pass the e-TDS/ TCS
              return file generated using RPU through the File Validation
              Utility (FVU) to ensure format level accuracy of the file. This
              utility is also freely downloadable from Protean (formerly NSDL
              eGov) e-Gov TIN website. In case the e-TDS/TCS return contains any
              errors, user should rectify the same in the excel utility itself.
              After rectifying the errors, user should pass the rectified e-TDS/
              TCS return through the FVU. This process should be continued till
              an error free e-TDS/ TCS return is generated.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EDS;
